import React from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { SkeletonLoading } from '@saleshandy/design-system';
import {
  ArrowNarrowDown,
  ArrowNarrowUp,
  ChevronDown,
  ChevronRight,
} from '@saleshandy/icons';
import { Dropdown } from 'react-bootstrap';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import classNames from 'classnames';
import validator from 'validator';
import Icon from '../../atoms/icon/icon';
import {
  IProps,
  Column,
  IState,
  Action,
  Sorting,
  TablePageLengthDropDown,
} from './types';
import Switch, { Size } from '../../atoms/switch';
import Checkbox from '../../atoms/checkbox';
import IconText from '../../atoms/icon-text';
import TextStacksDirectional from '../../molecules/text-stacks-directional';
import ContactNameField from '../../molecules/contact-name-field';
import { difference } from '../../../../utils/set';
import { SequenceNameField } from '../../molecules/sequence-name-field';
import { TeamMemberNameField } from '../../molecules/team-member-name-field';
import { OverlayTooltip, Placement } from '../../overlay';
import PaginationWrapper from './pagination-wrapper';
import { constants } from '../../../../enums/constants';
import ImageIcon from '../../../../components/images/image-icon';
import { Images } from '../../../../app-constants';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import EmptyList from '../../molecules/empty-list/empty-list';
import {
  getFinalColumns,
  getKeyAndId,
  getNoDataClassName,
  getShouldShowPagination,
  getTotalSize,
  showingLengthPerPageOptions,
} from './helper';
import hasPermission from '../../../../utils/access-control/has-permission';
import { Permissions } from '../../../../utils/access-control/enums/permissions';
import Select from '../../select';
import Input from '../../input/input';
import ColumnsSelector from './columns-selector';

class Table extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedRows: [],
      expandedRowIds: [],
      goToPage: 1,
      showRowsPerPage: showingLengthPerPageOptions[0],
      showBounce: false,
      isError: false,
      pgOptions: showingLengthPerPageOptions,
    };
    this.onSelectedRowsUpdate = this.onSelectedRowsUpdate.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onRowSelectAllHandler = this.onRowSelectAllHandler.bind(this);
    this.getCoreComponent = this.getCoreComponent.bind(this);
    this.resetSelectedRows = this.resetSelectedRows.bind(this);
    this.resetPagination = this.resetPagination.bind(this);
    this.onResetShowRowsPerPage = this.onResetShowRowsPerPage.bind(this);
    this.selectAllCurrentPageRows = this.selectAllCurrentPageRows.bind(this);
  }

  componentDidMount() {
    const { tableRef, defaultSelectedRows } = this.props;

    if (tableRef) {
      tableRef(this);
    }

    if (defaultSelectedRows?.length > 0) {
      this.setState({ selectedRows: defaultSelectedRows });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { resetSelected, handleResetSelected } = this.props;
    const { selectedRows, showRowsPerPage } = this.state;

    if (resetSelected && selectedRows.length === 0) {
      handleResetSelected?.();
    }

    if (resetSelected && selectedRows.length > 0) {
      this.onSelectedRowsUpdate([]);
      handleResetSelected?.();

      if (showRowsPerPage?.key !== showingLengthPerPageOptions[0]?.key) {
        this.onResetShowRowsPerPage();
      }
    }

    const { resetExpandedRows, id } = this.props;
    const { expandedRowIds } = this.state;

    if (
      (id !== prevProps.id ||
        resetExpandedRows !== prevProps.resetExpandedRows) &&
      expandedRowIds.length > 0
    ) {
      this.removeAllExpandRows();
    }

    const { isRefreshClicked, setOnRefresh } = this.props;
    if (prevProps.isRefreshClicked !== isRefreshClicked && isRefreshClicked) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ goToPage: 1 });
      setOnRefresh(false);
    }
  }

  componentWillUnmount() {
    const { tableRef } = this.props;

    if (tableRef) {
      tableRef(undefined);
    }
  }

  onResetShowRowsPerPage() {
    this.setState({
      showRowsPerPage: showingLengthPerPageOptions[0],
    });
  }

  onSelectedRowsUpdate(value) {
    this.setState({ selectedRows: value });
  }

  onChangeHandler(
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
    cell: any,
    row: any,
    index: number,
  ) {
    const { onChange } = this.props;
    onChange(checked, event, cell, row, index);
  }

  onRowSelect(row: any, isSelect: boolean) {
    const { selectedRows } = this.state;
    const { onRowSelect, maxSelectedRowsLimit } = this.props;

    if (isSelect) {
      const selectHandler = () => {
        this.setState(
          () => ({
            selectedRows: selectedRows?.includes(row.id)
              ? selectedRows
              : [...selectedRows, row.id],
          }),
          () => {
            !!onRowSelect && onRowSelect(row, isSelect);
          },
        );
      };

      if (maxSelectedRowsLimit) {
        const selectedRowsLength = selectedRows.length;

        if (
          selectedRowsLength !== maxSelectedRowsLimit &&
          selectedRowsLength < maxSelectedRowsLimit
        ) {
          selectHandler();
        } else {
          return false;
        }
      } else {
        selectHandler();
      }
    } else {
      this.setState(
        () => ({
          selectedRows: selectedRows.filter((x) => x !== row.id),
        }),
        () => {
          !!onRowSelect && onRowSelect(row, isSelect);
        },
      );
    }
    return null;
  }

  onRowSelectAllHandler(isSelect: boolean, rows: any) {
    const ids = rows.map((r) => r.id);
    const { selectedRows } = this.state;
    const { onRowSelectAll, maxSelectedRowsLimit } = this.props;

    if (isSelect) {
      const selectHandler = (rowIds) => {
        this.setState(
          () => ({
            selectedRows: rowIds,
          }),
          () => {
            !!onRowSelectAll && onRowSelectAll(rows, isSelect);
          },
        );
      };

      if (maxSelectedRowsLimit) {
        const remainingSelectionCount =
          maxSelectedRowsLimit - selectedRows.length;

        if (remainingSelectionCount > 0) {
          const idsToSelect = ids.slice(0, remainingSelectionCount);
          const rowIds = [...selectedRows, ...idsToSelect];
          selectHandler(rowIds);
          return rowIds;
        }
        return [];
      }

      const rowIds = [...selectedRows, ...ids];

      selectHandler(rowIds);
      return rowIds;
    }

    const rowIds = Array.from(difference(new Set(selectedRows), new Set(ids)));
    const uniqueRows = Array.from(
      new Set(rows.map((obj) => obj.id)),
    ).map((id) => rows.find((obj) => obj.id === id));

    this.setState({ selectedRows: rowIds }, () => {
      !!onRowSelectAll && onRowSelectAll(uniqueRows, isSelect);
    });

    return rowIds;
  }

  getCoreComponent(
    component: string,
    cell: any,
    row: any,
    index: number,
    componentClasses: string,
    onClick: any,
    switchOffTooltip = 'Resume',
    switchOnTooltip = 'Pause',
    tooltipPlacement = Placement.Bottom,
    overlayTooltipClass = '',
  ) {
    if (cell !== null && cell !== undefined) {
      const tooltipText = cell ? switchOnTooltip : switchOffTooltip;
      switch (component) {
        case 'switch':
          return hasPermission(Permissions.SEQUENCE_UPDATE) ? (
            <Switch
              placement={tooltipPlacement}
              tooltip={tooltipText}
              className={`table-switch ${componentClasses}`}
              overlayTooltipClass={overlayTooltipClass}
              checked={cell}
              size={Size.Small}
              onChange={(checked, event) =>
                this.onChangeHandler(checked, event, cell, row, index)
              }
            />
          ) : (
            <></>
          );
        case 'checkbox':
          return (
            <Checkbox
              className={componentClasses}
              checked={cell}
              onChange={(checked, event) =>
                this.onChangeHandler(checked, event, cell, row, index)
              }
            />
          );
        case 'text-stack':
          return <TextStacksDirectional stacks={cell} />;
        case 'icon-text':
          return <IconText text={cell.text} icon={cell.icon} />;
        case 'contact-name-field':
          return <ContactNameField cell={cell} onClick={onClick} />;
        case 'gray-text':
          return <span style={{ color: '#595959' }}>{cell}</span>;
        case 'sequence-name-field':
          return <SequenceNameField name={cell} row={row} />;
        case 'team-member-name-field':
          return <TeamMemberNameField name={cell} />;
        default:
          break;
      }
    }
    return null;
  }

  resetSelectedRows = () => {
    this.setState({ selectedRows: [] });
  };

  resetPagination = () => {
    const resetOptions = showingLengthPerPageOptions.map((option) => ({
      key: option.value.toString(),
      value: option.value,
    }));

    this.setState({
      pgOptions: resetOptions,
      showRowsPerPage: resetOptions[0],
      goToPage: 1,
    });
  };

  selectAllCurrentPageRows = () => {
    const { data, deselectedRows } = this.props;

    const temp = data.filter(({ id }) => !deselectedRows?.includes(id));

    this.onRowSelectAllHandler(true, temp);
  };

  sortCaret = (sortOptions, order: Sorting) => {
    if (!sortOptions) {
      return null;
    }
    if (order === Sorting.Ascending) {
      return <Icon identifier="arrow-up" />;
    }
    if (order === Sorting.Descending) {
      return <Icon identifier="arrow-down" />;
    }
    return null;
  };

  renderSortIcon = (field, order) => {
    const { sort } = this.props;
    if (field === sort?.dataField) {
      if (order === Sorting.Ascending) {
        return <ArrowNarrowUp />;
      }
      if (order === Sorting.Descending) {
        return <ArrowNarrowDown />;
      }
    }
    return <ImageIcon src={Images.ArrowsSort} />;
  };

  generateFormatter = (columns: Column[]) => {
    const { showColumnsToggler = false, selectedColumnsIds = [] } = this.props;

    let filteredColumns: Column[] = columns;

    if (showColumnsToggler && selectedColumnsIds?.length > 0) {
      filteredColumns = columns.filter((col) =>
        selectedColumnsIds.includes(col.id),
      );
    }

    return filteredColumns.map((col: Column, index: number) => {
      const {
        cellClasses,
        componentClasses,
        dataField,
        text,
        component,
        align,
        headerAlign,
        isDummyField,
        onClick,
        style,
        headerStyle,
        headerClasses: hdrCls,
        sort,
        onSort,
        switchOffTooltip,
        switchOnTooltip,
        tooltipPlacement,
        overlayTooltipClass,
        headerLoadingSkeleton,
        cellLoadingSkeleton,
        isCellClickable = true,
      } = col;
      const { sort: sortOptions, isLoading } = this.props;
      return {
        dataField,
        text,
        classes: cellClasses,
        align,
        headerAlign,
        isDummyField,
        style,
        headerStyle,
        headerClasses: `${sort} ${hdrCls || ''}`,
        isCellClickable,
        sort,
        onSort: (field, order) => {
          const { expandedRowIds } = this.state;

          if (expandedRowIds.length > 0) {
            this.removeAllExpandRows();
          }

          onSort(field, order);
        },
        sortCaret: (order: Sorting) => this.sortCaret(sortOptions, order),
        headerFormatter: (column) => {
          if (isLoading) {
            return headerLoadingSkeleton;
          }

          return (
            <div className="d-flex">
              <span className="semibold-1 gray-txt-12">{column.text}</span>
              {column.text.length > 0 && column.sort && (
                <div role="button" className="sort-button">
                  {this.renderSortIcon(column.dataField, sortOptions?.order)}
                </div>
              )}
            </div>
          );
        },
        formatter: (cell, row, rowIndex) => {
          if (isLoading) {
            return cellLoadingSkeleton;
          }

          if (typeof component === 'string') {
            return this.getCoreComponent(
              component,
              cell,
              row,
              index,
              componentClasses,
              onClick,
              switchOffTooltip,
              switchOnTooltip,
              tooltipPlacement,
              overlayTooltipClass,
            );
          }
          if (component instanceof React.Component) {
            return component;
          }
          if (typeof component === 'function') {
            return component(cell, row, rowIndex);
          }
          return cell;
        },
      };
    });
  };

  conditionCheck = (row, action) => {
    let tempRow = row;

    const { condition, conditionKey } = action;

    if (!conditionKey) {
      return true;
    }

    if (row.customDomain) {
      tempRow = { ...row.customDomain };
    }

    if (condition) {
      return tempRow[conditionKey];
    }
    return !tempRow[conditionKey];
  };

  renderIconInDropDownMenu = (action, row, index) => {
    const { onAction } = this.props;

    return !action.customIcon ? (
      <Icon
        key={index}
        onClick={() => onAction(action.key, row)}
        identifier={action.icon}
        className="lfloat gray-txt-6 mx-1"
      />
    ) : (
      <img
        {...accessibleOnClick(() => onAction(action.key, row))}
        src={action.icon}
        alt={action.displayName}
      />
    );
  };

  generateDropdownItemsListFromActions = (actions: Action[], row) => {
    const { onAction, isLoading, newThemeActions } = this.props;

    const dropdownItems = [];
    const gridItems = [];
    let index = 0;
    actions.forEach((action) => {
      if (action.position === 'out') {
        if (isLoading) {
          gridItems.push(<SkeletonLoading width={24} height={24} circle />);
        } else {
          gridItems.push(
            <OverlayTooltip text={action.displayName} key={action.key}>
              <div>
                {!action.customIcon && !action.iconElement && (
                  <Icon
                    key={index}
                    onClick={() => onAction(action.key, row)}
                    identifier={action.icon}
                    className={`lfloat gray-txt-6 mx-1 ${
                      newThemeActions ? 'action-icon-generic' : ''
                    }`}
                  />
                )}

                {action.customIcon && (
                  <img
                    {...accessibleOnClick(() => onAction(action.key, row))}
                    src={action.icon}
                    alt={action.displayName}
                  />
                )}

                {action.iconElement && (
                  <div {...accessibleOnClick(() => onAction(action.key, row))}>
                    {action.iconElement}
                  </div>
                )}
              </div>
            </OverlayTooltip>,
          );
        }
      } else {
        this.conditionCheck(row, action) &&
          dropdownItems.push(
            <Dropdown.Item
              onClick={() => onAction(action.key, row)}
              key={index}
              bsPrefix="dropdown-item-custom"
            >
              {action.isSVGIcon && action.SVGIcon}
              {!action.customIcon && action.icon && (
                <Icon identifier={action.icon} className="lfloat gray-txt-6" />
              )}

              {action.imageIcon && (
                <ImageIcon src={action.imageIcon} alt="Image icon" />
              )}

              {action.customIcon && action.icon && (
                <img
                  {...accessibleOnClick(() => onAction(action.key, row))}
                  src={action.icon}
                  alt={action.displayName}
                  className="lfloat gray-txt-6"
                />
              )}

              {action.iconElement && action.iconElement}

              {action.displayName && (
                <span className="regular-2 gray-txt-15 ml-2">
                  {action.displayName}
                </span>
              )}
            </Dropdown.Item>,
          );
      }
      index += 1;
    });

    return { gridItems, dropdownItems };
  };

  generateActionsColumn = (
    actions,
    headerVisibleForGenerateColumn,
    borderOverActions,
  ) => {
    const {
      actionColumnName = '',
      isLoading,
      onActionDropdownToggle,
      newThemeActions,
    } = this.props;

    return {
      dataField: 'actions',
      text: isLoading ? (
        <SkeletonLoading width={45} height={14} />
      ) : (
        actionColumnName || 'Actions'
      ),
      headerClasses: 'bs-table-thead',
      formatter: (cell, row) => {
        const generatedDropdownItemsListFromActions =
          typeof actions === 'function'
            ? this.generateDropdownItemsListFromActions(actions(cell, row), row)
            : this.generateDropdownItemsListFromActions(actions, row);
        return generatedDropdownItemsListFromActions !== null ? (
          <div className={newThemeActions ? 'grid-center' : 'grid-flex'}>
            {/* Showing grid action items */}
            {generatedDropdownItemsListFromActions.gridItems.length !== 0 &&
              generatedDropdownItemsListFromActions.gridItems}

            {/* showing dropdown action items */}
            {generatedDropdownItemsListFromActions.dropdownItems.length !== 0 &&
              isLoading && <SkeletonLoading width={24} height={24} circle />}

            {generatedDropdownItemsListFromActions.dropdownItems.length !== 0 &&
              !isLoading && (
                <div className="grid-action">
                  <Dropdown
                    bsPrefix="dropdown-custom"
                    drop="down"
                    {...(onActionDropdownToggle
                      ? { onToggle: onActionDropdownToggle }
                      : {})}
                  >
                    <Dropdown.Toggle
                      bsPrefix="dropdown-toggle-custom"
                      variant="dropdown-custom"
                      id="dropdown-basic"
                    >
                      <Icon
                        identifier="more-vertical-alt"
                        className="icon-hover gray-txt-15"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu bsPrefix="dropdown-menu-custom">
                      {generatedDropdownItemsListFromActions.dropdownItems}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
          </div>
        ) : null;
      },
      isDummyField: true,
      align: 'right',
      headerAlign: 'center',
      headerStyle: {
        display: headerVisibleForGenerateColumn ? 'table-cell' : 'none',
      },
      style: borderOverActions
        ? { borderTop: '1px solid #dee2e6' }
        : { borderTop: 'none' },
    };
  };

  generateTogglerColumn = () => {
    const {
      isLoading,
      selectedColumnsIds,
      onColumnSelect,
      columns,
    } = this.props;
    const { expandedRowIds } = this.state;

    const handleOnColumnSelect = (colIds: number[]) => {
      if (expandedRowIds.length > 0) {
        this.removeAllExpandRows();
      }
      onColumnSelect(colIds);
    };

    return {
      dataField: 'togglerColumn',
      text: '',
      headerClasses: 'columns-toggler',
      headerFormatter: () =>
        isLoading ? (
          <SkeletonLoading width={20} height={14} />
        ) : (
          <ColumnsSelector
            columns={columns}
            selectedColumnsIds={selectedColumnsIds}
            onColumnSelect={handleOnColumnSelect}
          />
        ),
      align: 'left',
      headerAlign: 'left',
      headerStyle: () => ({
        minWidth: '2.25rem',
        width: '2.25rem',
        maxWidth: '2.25rem',
        paddingRight: '0.5rem',
      }),
      classes: 'toggler-column',
    };
  };

  generateExpandColumn = () => {
    const { isLoading, isExpandColumnPositionFixed } = this.props;
    return {
      dataField: 'expandColumn',
      text: '',
      headerFormatter: () =>
        isLoading ? <SkeletonLoading width={20} height={14} /> : <span />,
      align: 'left',
      headerAlign: 'left',
      headerStyle: () => ({
        minWidth: '2.25rem',
        width: '2.25rem',
        paddingRight: '0.5rem',
      }),
      classes: `columns-expand ${
        isExpandColumnPositionFixed ? 'fixed-td' : ''
      }`,
      headerClasses: isExpandColumnPositionFixed ? 'fixed-th' : '',
      cellLoadingSkeleton: <SkeletonLoading width={20} height={14} />,
      attrs: (cell, row, rowIndex) => ({
        'row-id': rowIndex + 1,
      }),
      formatter: (_cell, _row, rowIndex) => {
        const { expandedRowIds } = this.state;

        if (isLoading) {
          return <SkeletonLoading width={20} height={14} />;
        }

        return (
          <div>
            {expandedRowIds.includes(rowIndex + 1) ? (
              <ChevronDown />
            ) : (
              <ChevronRight />
            )}
          </div>
        );
      },
      events: {
        onClick: (_e, _column, _columnIndex, _row, rowIndex) => {
          this.handleExpandRows(rowIndex);
        },
      },
    };
  };

  getTotalPages = () => {
    const { paginationOptions, sequenceFixedPageSize } = this.props;
    const { showRowsPerPage } = this.state;

    const totalSize = getTotalSize({
      paginationOptions,
      sequenceFixedPageSize,
    });
    return Math.ceil(totalSize / showRowsPerPage?.value);
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      const { onPaginationOptionsChange } = this.props;
      onPaginationOptionsChange({ page, limit: sizePerPage });
      this.setState({ goToPage: page });

      const { expandedRowIds } = this.state;

      if (expandedRowIds.length > 0) {
        this.removeAllExpandRows();
      }
    }
  };

  handelChangeGoToPage = (page) => {
    const { paginationOptions, sequenceFixedPageSize } = this.props;
    const { showRowsPerPage } = this.state;

    const totalSize = getTotalSize({
      paginationOptions,
      sequenceFixedPageSize,
    });

    if (page < 1) {
      return;
    }

    if (page > Math.ceil(totalSize / showRowsPerPage?.value)) {
      return;
    }

    this.setState({
      goToPage: page,
    });

    const { expandedRowIds } = this.state;

    if (expandedRowIds.length > 0) {
      this.removeAllExpandRows();
    }
  };

  handleOnBlur = () => {
    const { goToPage } = this.state;
    const totalPage = this.getTotalPages();

    if (
      goToPage > totalPage ||
      goToPage < 1 ||
      !validator.isNumeric(goToPage.toString())
    ) {
      this.setState({
        isError: true,
      });
    } else {
      this.setState({
        isError: false,
      });
    }
  };

  addErrorVariantToInput = () => {
    this.setState({
      showBounce: true,
      isError: true,
    });

    setTimeout(() => {
      this.setState({
        showBounce: false,
      });
    }, 1000);
  };

  isGoToPageValid = (goToPageValue) => {
    const totalPage = this.getTotalPages();

    if (
      goToPageValue < 1 ||
      goToPageValue > totalPage ||
      !validator.isNumeric(goToPageValue.toString())
    ) {
      this.addErrorVariantToInput();

      return false;
    }

    this.setState({
      isError: false,
    });
    return true;
  };

  handleGoToPage = (e) => {
    e.preventDefault();

    const { goToPage, showRowsPerPage } = this.state;

    const checkPageIsValid = this.isGoToPageValid(goToPage);

    if (checkPageIsValid) {
      this.handleTableChange('pagination', {
        page: Number(goToPage),
        sizePerPage: showRowsPerPage?.value,
      });
    }
  };

  handleSelectRowsPerPage = (option) => {
    const { paginationOptions, sequenceFixedPageSize } = this.props;

    const totalSize = getTotalSize({
      paginationOptions,
      sequenceFixedPageSize,
    });

    let customShowingKey;
    const renderOption: TablePageLengthDropDown = option;

    if (option.value > totalSize) {
      customShowingKey = totalSize;
      renderOption.key = customShowingKey;
    }

    this.setState({ showRowsPerPage: renderOption, goToPage: 1 });
    this.handleTableChange('pagination', {
      page: 1,
      sizePerPage: option?.value,
    });
  };

  removeExpandRow = (rowIndex: number) => {
    const { expandedRowIds } = this.state;
    const { data, id } = this.props;

    const tbody = document.querySelector(`.${id}`);

    const targetRow = tbody
      ?.querySelector(`tr > td[row-id="${rowIndex + 1}"]`)
      ?.closest('tr');

    if (targetRow) {
      data[rowIndex]?.users?.forEach(() => {
        const rowToRemove = targetRow.nextSibling;
        if (rowToRemove) {
          tbody.removeChild(rowToRemove);
        }
      });
    }

    this.setState({
      expandedRowIds: expandedRowIds.filter((rowId) => rowId !== rowIndex + 1),
    });
  };

  removeAllExpandRows = () => {
    const { expandedRowIds } = this.state;
    const { data, id } = this.props;

    const tbody = document.querySelector(`.${id}`);

    expandedRowIds.forEach((rowId) => {
      const targetRow = tbody
        ?.querySelector(`tr > td[row-id="${rowId}"]`)
        ?.closest('tr');

      if (targetRow) {
        data[rowId - 1]?.users?.forEach(() => {
          const rowToRemove = targetRow.nextSibling;
          if (rowToRemove) {
            tbody.removeChild(rowToRemove);
          }
        });
      }
    });

    this.setState({ expandedRowIds: [] });
  };

  handleExpandRows = (rowIndex: number) => {
    const {
      columns,
      data,
      showExpandColumn,
      showColumnsToggler,
      selectedColumnsIds,
      id,
    } = this.props;

    const { expandedRowIds } = this.state;
    const rowId = rowIndex + 1;

    if (expandedRowIds.includes(rowId)) {
      this.removeExpandRow(rowIndex);
    } else {
      const tbody = document.querySelector(`.${id}`);

      const targetRow = tbody
        ?.querySelector(`tr > td[row-id="${rowId}"]`)
        .closest('tr');

      if (targetRow) {
        let filteredColumns: Column[] = columns;

        if (showColumnsToggler && selectedColumnsIds?.length > 0) {
          filteredColumns = columns.filter((col) =>
            selectedColumnsIds.includes(col.id),
          );
          filteredColumns.push(this.generateTogglerColumn());
        }
        if (showExpandColumn) {
          filteredColumns.unshift(this.generateExpandColumn());
        }

        filteredColumns = filteredColumns.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.dataField === value.dataField),
        );

        data[rowId - 1]?.users?.reverse()?.forEach((user) => {
          const tr = document.createElement('tr');
          tr.classList.add('expand-row');

          filteredColumns.forEach((col) => {
            const td = document.createElement('td');
            col?.cellClasses?.split(' ')?.forEach((cls) => {
              td.classList.add(cls);
            });

            col?.classes
              ?.toString()
              ?.split(' ')
              ?.forEach((cls) => {
                td.classList.add(cls);
              });

            const renderedJSX =
              col.dataField === 'togglerColumn' || !col.expandedCellRenderer ? (
                <></>
              ) : (
                col.expandedCellRenderer(user[col.dataField], user)
              );

            ReactDOM.render(renderedJSX, td);

            tr.appendChild(td);
          });

          tbody.insertBefore(tr, targetRow.nextSibling);
        });
      }

      this.setState({ expandedRowIds: [...expandedRowIds, rowId] });
    }
  };

  render() {
    const {
      data,
      columns,
      actions,
      headerWrapperClasses = '',
      bodyWrapperClasses = '',
      sort,
      tableWrapperClasses = '',
      rowClasses,
      headerVisibleForGenerateColumn,
      borderOverActions,
      paginationOptions,
      pagination,
      id,
      onRowSelect,
      onRowSelectAll,
      isNewPagination,
      isLoading,
      sequenceFixedPageSize,
      maxSelectedRowsLimit,
      showColumnsToggler,
      showExpandColumn,
      loadingRowsCount = 4,
      fixedPageSize,
    } = this.props;

    const {
      selectedRows,
      goToPage,
      showRowsPerPage,
      showBounce,
      isError,
      pgOptions,
    } = this.state;
    const dataColumns = this.generateFormatter(columns);
    const finalColumns: Column[] = getFinalColumns({
      actions,
      dataColumns,
      generateActionsColumn: this.generateActionsColumn,
      headerVisibleForGenerateColumn,
      borderOverActions,
      showColumnsToggler,
      generateTogglerColumn: this.generateTogglerColumn,
      showExpandColumn,
      generateExpandColumn: this.generateExpandColumn,
    });
    const totalSize = getTotalSize({
      paginationOptions,
      sequenceFixedPageSize,
    });

    const paginationShow = getShouldShowPagination({
      pagination,
      totalSize,
      isNewPagination,
      sequenceFixedPageSize,
      fixedPageSize,
    });

    let defaultSize = sequenceFixedPageSize
      ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
      : constants.DEFAULT_PAGE_SIZE;

    if(fixedPageSize){
      defaultSize = fixedPageSize || defaultSize;
    }

    const start = (paginationOptions.options.page - 1) * defaultSize + 1;
    const end =
      (paginationOptions.options.page - 1) * defaultSize + data.length;

    // Go To Page classes
    const goToPageInputClasses = classNames([
      'page-number-input',
      {
        'input-bounce': showBounce,
      },
    ]);

    const tableData = isLoading
      ? Array.from(Array(loadingRowsCount).keys())
      : data;

    const renderSelectCheckbox = (options, checkedRows) => {
      if (isLoading) {
        return <SkeletonLoading width={16} height={16} />;
      }

      if (
        maxSelectedRowsLimit &&
        checkedRows.length === maxSelectedRowsLimit &&
        (options.rowKey || !options.checked) &&
        !checkedRows.includes(options?.rowKey)
      ) {
        return (
          <OverlayTooltip
            text={`Max ${maxSelectedRowsLimit} sequences allowed`}
            placement={Placement.BottomStart}
            className="max-rows-selected"
          >
            <Checkbox
              checked={options.checked}
              className="show-disable-style"
            />
          </OverlayTooltip>
        );
      }
      return <Checkbox checked={options.checked} />;
    };

    const { onRowClickHandler } = this.props;

    const tableId =
      maxSelectedRowsLimit && maxSelectedRowsLimit === selectedRows.length
        ? 'disabled-table'
        : '';

    return (
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: paginationOptions.options.page,
          sizePerPage: isNewPagination ? showRowsPerPage?.value : defaultSize,
          totalSize,
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className={`table-list ${tableWrapperClasses}`}>
              <div className="bs-table bs-table-small">
                <div className="bs-table-container">
                  <div className="bs-table-content">
                    <BootstrapTable
                      key={getKeyAndId(id) || tableId}
                      id={getKeyAndId(id) || tableId}
                      pagination={!paginationShow && paginationFactory({})}
                      remote
                      selectRow={
                        onRowSelect && onRowSelectAll
                          ? {
                              mode: 'checkbox',
                              selected: selectedRows,
                              onSelect: this.onRowSelect,
                              onSelectAll: this.onRowSelectAllHandler,
                              selectionHeaderRenderer: (options) =>
                                renderSelectCheckbox(options, selectedRows),
                              selectionRenderer: (options) =>
                                renderSelectCheckbox(options, selectedRows),
                              headerColumnStyle: { width: '20px' },
                            }
                          : undefined
                      }
                      sort={
                        sort && {
                          dataField: sort.dataField,
                          order: sort.order,
                        }
                      }
                      bordered={false}
                      classes="table-organism"
                      keyField="id"
                      data={tableData}
                      columns={finalColumns.map((column) => ({
                        ...column,
                        ...(column.isCellClickable && {
                          events: {
                            onClick: (e, col, columnIndex, row) => {
                              if (col.text !== '' && col.isCellClickable) {
                                onRowClickHandler?.(row);
                              }
                            },
                          },
                        }),
                      }))}
                      bodyClasses={`table-organism-body ${bodyWrapperClasses} ${getNoDataClassName(
                        tableData,
                      )}`}
                      headerWrapperClasses={`table-organism-header ${headerWrapperClasses}`}
                      rowClasses={rowClasses}
                      onTableChange={this.handleTableChange}
                      noDataIndication={
                        !isLoading ? (
                          <EmptyList
                            title="No Result Found!"
                            description=""
                            imgSrc={Images.EmptyData1}
                            isVertical={true}
                          />
                        ) : null
                      }
                      {...paginationTableProps}
                    />
                  </div>
                  {!isLoading && paginationShow && (
                    <div
                      className={classNames([
                        'pagination-container d-flex',
                        {
                          'pagination-container-new': isNewPagination,
                        },
                      ])}
                    >
                      <div className="pagination-row d-flex">
                        {isNewPagination ? (
                          <>
                            <div className="pagination-select-count d-flex align-items-center">
                              <span className="regular-2 popover-arrow-color-txt mr-2">
                                Showing
                              </span>
                              <Select<TablePageLengthDropDown>
                                options={pgOptions}
                                selectedOptionKey={showRowsPerPage?.key}
                                selectedOptionRenderer={([option]) => (
                                  <span className="blue-txt-15">
                                    {option?.key}
                                  </span>
                                )}
                                onChange={([option]) =>
                                  this.handleSelectRowsPerPage(option)
                                }
                                optionRenderer={(option) => (
                                  <span>{option?.value}</span>
                                )}
                                placeholder="Select Plan"
                                placement={Placement.Top}
                              />
                              <span className="regular-2 popover-arrow-color-txt ml-2 total-pagination-count">{`out of ${totalSize}`}</span>
                            </div>

                            <div className="divider" />

                            <div className="pagination-page-input d-flex align-items-center">
                              <span className="regular-2 popover-arrow-color-txt mr-2 go-to-page">
                                Go to page:
                              </span>
                              <div className="mt-1">
                                <form onSubmit={this.handleGoToPage}>
                                  <Input
                                    value={goToPage}
                                    className={goToPageInputClasses}
                                    onChange={this.handelChangeGoToPage}
                                    type="number"
                                    min={1}
                                    max={Math.ceil(
                                      totalSize / showRowsPerPage?.value,
                                    )}
                                    variant={isError && Input.Variant.Error}
                                  />
                                </form>
                              </div>
                              <span className="regular-2 popover-arrow-color-txt ml-2">{`/${Math.ceil(
                                totalSize / showRowsPerPage?.value,
                              )}`}</span>
                            </div>
                          </>
                        ) : (
                          <span className="regular-2">{`${start}-${end} of ${totalSize}`}</span>
                        )}
                        <PaginationWrapper
                          {...paginationProps}
                          isNewPagination={isNewPagination}
                          showRowsPerPage={showRowsPerPage?.value}
                          sequenceFixedPageSize={sequenceFixedPageSize}
                          fixedPageSize={fixedPageSize}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    );
  }
}

export default Table;
