import { EmailInboxFilters } from '../../type';
import { getEmailInboxFiltersFromLocalStore } from './email-inbox-filters-local-storage-handler';
import store from '../../../../store/store';
import durationOptions from './date-filter-options';

export const getDefaultFilters = (): EmailInboxFilters => {
  const state = store.getState();

  const defaultDate = durationOptions.find(
    (item) => item.label === 'last_30_days',
  );

  return {
    page: 1,
    limit: 50,
    categoryId: 0,
    search: '',
    sequenceIds: [],
    emailAccountIds: [],
    owners: [],
    date: null,
    isRead: null,
    clients: [],
  };
};

export const getInitialFilters = (): EmailInboxFilters => {
  const defaultFilters = getDefaultFilters();
  const filtersFromLocalStorage = getEmailInboxFiltersFromLocalStore();

  if (filtersFromLocalStorage && filtersFromLocalStorage.date) {
    if (filtersFromLocalStorage.date.label === 'custom') {
      return {
        ...defaultFilters,
        ...filtersFromLocalStorage,
        page: 1,
      };
    }

    return {
      ...defaultFilters,
      ...filtersFromLocalStorage,
      date: durationOptions.find(
        (item) => item.label === filtersFromLocalStorage.date.label,
      ),
      page: 1,
    };
  }

  return {
    ...defaultFilters,
    ...filtersFromLocalStorage,
    page: 1,
  };
};
