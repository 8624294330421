import React, { memo, useCallback } from 'react';
import { map } from 'lodash';
import FilterTab from '../../../../../../../shared/design-system/components/atoms/filter-tab';
import HorizontalMenu from '../../../../../../../shared/design-system/components/atoms/horizontal-menu';
import { filterToProperties } from '../../../../../helpers/prospect-filters-resolver';
import { Filters } from '../../../../../../../shared/types/prospects-filters';
import { IProps } from './types';
import { constants } from '../../../../../../../shared/enums/constants';
import { emailFilterToProperties } from '../../../../../../sequence/components/sequence-single-content/components/sequence-single-emails/helper/sequence-emails-filters-resolver';

/**
 * Renders all the applied Filters.
 * If the applied filters exceeds the view port then they are horizontally scrollable.
 */
const ProspectsFilterBar: React.FC<IProps> = ({
  prospectsFilters,
  resetProspectsFilters,
  getProspectsCount,
  getProspects,
  handleClearSelection,
  isLoading,
  isSequenceEmailsFilter = false,
  search,
}) => {
  let isEmpty = true;
  const filterToPropertiesTemp = isSequenceEmailsFilter
    ? emailFilterToProperties
    : filterToProperties;
  Object.keys(prospectsFilters)?.forEach((obj) => {
    if (prospectsFilters[obj]?.length > 0) {
      isEmpty = false;
    }
  });

  const onCancel = useCallback(
    (filter: Filters) => {
      resetProspectsFilters(filter);
      getProspects?.({
        pageNum: 0,
        pageSize: constants.PROSPECT_PAGE_SIZE,
        search,
      });
      getProspectsCount?.({ search });
      handleClearSelection && handleClearSelection();
    },
    [resetProspectsFilters, getProspectsCount, getProspects],
  );

  if (isLoading || isEmpty) {
    return null;
  }

  return (
    <HorizontalMenu>
      {Object.keys(prospectsFilters)?.map((key, idx) => (
        <FilterTab
          filters={map(
            key === Filters.Status
              ? prospectsFilters[key]?.filter((item) => !item?.isHeaderFilter)
              : prospectsFilters[key],
            filterToPropertiesTemp[key]?.displayNameKey,
          )}
          name={
            isSequenceEmailsFilter
              ? emailFilterToProperties[key]?.displayLabel
              : key
          }
          key={prospectsFilters[key]}
          itemId={idx}
          onCancel={() => onCancel(key as Filters)}
          separator={filterToPropertiesTemp[key]?.separator}
        />
      ))}
    </HorizontalMenu>
  );
};

export default memo(ProspectsFilterBar);
