import { DateTime } from 'luxon';
import store from '../../../../store/store';

import { Sequence } from '../../../sequence/types';
import {
  EmailAccountsType,
  EmailCategoryType,
  EmailInboxFilters,
} from '../../type';
import { getEmailCategoriesForFilter } from '../../components/unified-inbox-filters-row/helper';
import {
  getIsAllClientsSelectedFromLocalStore,
  getIsAllEmailAccountsSelectedFromLocalStore,
  getIsAllOwnersSelectedFromLocalStore,
  getIsAllSequencesSelectedFromLocalStore,
} from './email-inbox-filters-local-storage-handler';
import { getDefaultFilters } from './get-default-filters';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import durationOptions from '../../../../shared/components/date-filter/date-filter-options';
import { TeamsAndUsersList } from '../../../settings/types/users-and-teams';

/* Function to filter and return the selected email category
based on the category value from query params */
const getSelectedEmailCategoryForFilter = (
  emailCategories: EmailCategoryType[],
  category: string,
) =>
  getEmailCategoriesForFilter(emailCategories).find(
    (item) => item.name === category,
  );

export const getAllSelectOwners = (teamsAndUsersList: TeamsAndUsersList[]) => {
  // Extract the users Id from teamsAndUsersList
  const usersId = teamsAndUsersList.reduce((userIds, team) => {
    userIds.push(...team.users.map((user) => user.id));
    return userIds;
  }, []);

  return usersId.filter((id, index, array) => array.indexOf(id) === index);
};

// Filter out the query params owners from the props owners
export const getFilteredOwner = (
  filteredOwners: number[],
  owners: string,
  teamsAndUsersList: TeamsAndUsersList[],
): number[] => {
  const isAllOwnersSelected = getIsAllOwnersSelectedFromLocalStore();

  if (teamsAndUsersList?.length && (owners === 'ALL' || isAllOwnersSelected)) {
    return getAllSelectOwners(teamsAndUsersList);
  }

  if (owners?.length || filteredOwners?.length) {
    const ownerIds: number[] = owners?.length
      ? owners?.split(',')?.map((owner) => parseInt(owner.trim(), 10))
      : filteredOwners;

    // Extract the users Id from teamsAndUsersList
    const usersId = teamsAndUsersList.reduce((userIds, team) => {
      userIds.push(...team.users.map((user) => user.id));
      return userIds;
    }, []);

    // If in the url params there are ownerId which is not our ownerId, so remove it
    return ownerIds.filter((value) => usersId.includes(value));
  }

  if (teamsAndUsersList?.length) {
    return getAllSelectOwners(teamsAndUsersList);
  }

  return [];
};

// Filter out the query params clients from the props clients
export const getFilteredClient = (
  filteredClients: number[],
  clients: string,
): number[] => {
  const isAllClientsSelected = getIsAllClientsSelectedFromLocalStore();

  if (clients === 'ALL' || isAllClientsSelected) {
    return filteredClients;
  }

  if (clients?.length) {
    const clientIds: number[] = clients
      ?.split(',')
      ?.map((client) => parseInt(client.trim(), 10));

    return clientIds.filter((value) => filteredClients.includes(value));
  }

  return [];
};

/* Function to filter and return the selected ids from
from the data array based on the value from query params */
const getSelectedIdsForFilter = (
  filters: EmailInboxFilters,
  data: any[],
  value: string,
  isAllValuesSelectedKey: boolean,
  dataKey: string,
  filterKey: 'sequenceIds' | 'emailAccountIds',
): number[] => {
  const selectedIds = [];

  if (!data && data.length === 0) {
    return selectedIds;
  }

  if (
    !hasPermission(Permissions.UNIFIED_INBOX_THREAD_FILTER) ||
    value === 'ALL'
  ) {
    // Select All Ids
    return selectedIds;
  }

  if (value && value !== '') {
    const valueFromQueryParams = value.split(',');

    data?.forEach((item) => {
      const selectedData = valueFromQueryParams.find(
        (name: string) => Number(name) === item[dataKey],
      );

      if (selectedData) {
        selectedIds.push(item.id);
      }
    });

    if (selectedIds.length === 0) {
      data?.forEach(({ id }) => {
        selectedIds.push(id);
      });
    }
    return selectedIds;
  }

  if (!isAllValuesSelectedKey && filters[filterKey].length > 0) {
    data?.forEach(({ id }) => {
      const selectedData = filters[filterKey].find(
        (name: number) => name === id,
      );

      if (selectedData) {
        selectedIds.push(id);
      }
    });
    return selectedIds;
  }

  if (!value) {
    // Select All Ids
    return selectedIds;
  }

  return selectedIds;
};

/* Function to filter and return the selected date object
based on the startDate and endDate value from query params */
const getSelectedDateForFilter = (
  displayText: string,
  startDate: string,
  endDate: string,
  filters: EmailInboxFilters,
) => {
  const state = store.getState();

  if (!hasPermission(Permissions.UNIFIED_INBOX_THREAD_FILTER)) {
    return getDefaultFilters().date;
  }

  let date = null;

  const selectedDate = durationOptions.find(
    (item) => item.displayText === displayText,
  );

  if (selectedDate) {
    if (selectedDate.displayText === 'Custom' && startDate && endDate) {
      const sDate = DateTime.fromJSDate(new Date(startDate)).setZone(
        state.myProfile.myProfile.timeZone,
      );
      const eDate = DateTime.fromJSDate(new Date(endDate)).setZone(
        state.myProfile.myProfile.timeZone,
      );
      date = {
        label: selectedDate.label,
        startDate: sDate,
        endDate: eDate,
        displayText: selectedDate.displayText,
      };
    } else {
      date = {
        label: selectedDate.label,
        startDate: selectedDate.startDate.setZone(
          state.myProfile.myProfile.timeZone,
        ),
        endDate: selectedDate.endDate.setZone(
          state.myProfile.myProfile.timeZone,
        ),
        displayText: selectedDate.displayText,
      };
    }
  } else {
    date = {
      label: filters?.date?.label,
      startDate: DateTime.fromJSDate(
        new Date(filters?.date?.startDate?.toString()),
      ).setZone(state.myProfile.myProfile?.timeZone),
      endDate: DateTime.fromJSDate(
        new Date(filters?.date?.endDate.toString()),
      ).setZone(state.myProfile.myProfile.timeZone),
      displayText: filters?.date?.displayText,
    };
  }

  return date;
};

/* The user can view unlimited threads and also can perform filters */
const getReturnValueForCaseOne = ({
  filters,
  page,
  search,
  category,
  selectCategory,
  selectedSequenceIds,
  selectedEmailAccountIds,
  selectedUsersIds,
  date,
  selectedClients,
}): EmailInboxFilters => ({
  ...filters,
  ...(page && { page }),
  ...(search && { search }),
  ...(category && { categoryId: selectCategory ? selectCategory.id : 0 }),
  ...(selectedSequenceIds.length > 0 && {
    sequenceIds: selectedSequenceIds,
  }),
  ...(selectedEmailAccountIds.length > 0 && {
    emailAccountIds: selectedEmailAccountIds,
  }),
  clients: selectedClients,
  ...(selectedUsersIds.length > 0 && {
    owners: selectedUsersIds,
  }),
  ...(date && { date }),
});

/* The user can view unlimited threads but cannot perform filters */
const getReturnValueForCaseTwo = ({
  filters,
  page,
  selectedSequenceIds,
  selectedEmailAccountIds,
  selectedUsersIds,
  date,
  selectedClients,
}): EmailInboxFilters => ({
  ...filters,
  ...(page && { page }),
  search: '',
  categoryId: 0,
  ...(selectedSequenceIds.length > 0 && {
    sequenceIds: selectedSequenceIds,
  }),
  clients: selectedClients,
  ...(selectedEmailAccountIds.length > 0 && {
    emailAccountIds: selectedEmailAccountIds,
  }),
  ...(selectedUsersIds.length > 0 && {
    owners: selectedUsersIds,
  }),
  ...(date && { date }),
});

/* The user cannot view unlimited threads but can perform filters */
const getReturnValueForCaseThree = ({
  filters,
  search,
  category,
  selectCategory,
  selectedSequenceIds,
  selectedEmailAccountIds,
  selectedUsersIds,
  date,
  selectedClients,
}): EmailInboxFilters => ({
  ...filters,
  page: 1,
  limit: 15,
  ...(search && { search }),
  ...(category && { categoryId: selectCategory ? selectCategory.id : 0 }),
  ...(selectedSequenceIds.length > 0 && {
    sequenceIds: selectedSequenceIds,
  }),
  ...(selectedEmailAccountIds.length > 0 && {
    emailAccountIds: selectedEmailAccountIds,
  }),
  clients: selectedClients,
  ...(selectedUsersIds.length > 0 && {
    owners: selectedUsersIds,
  }),
  ...(date && { date }),
});

export const getEmailInboxFiltersBasedOnQuery = (
  filters: EmailInboxFilters,
  searchParams: {
    page: number;
    category: string;
    search: string;
    sequence: string;
    email: string;
    duration: string;
    startDate: string;
    endDate: string;
    owners: string;
    clients: string;
  },
  emailCategories: EmailCategoryType[],
  sequences: Sequence[],
  emails: EmailAccountsType[],
  teamsAndUsersList: TeamsAndUsersList[],
): EmailInboxFilters => {
  const {
    page,
    category,
    search,
    sequence,
    email,
    duration,
    startDate,
    endDate,
    owners,
    clients,
  } = searchParams;

  const selectCategory = getSelectedEmailCategoryForFilter(
    emailCategories,
    category,
  );

  const selectedSequenceIds = getSelectedIdsForFilter(
    filters,
    sequences,
    sequence,
    getIsAllSequencesSelectedFromLocalStore(),
    'id',
    'sequenceIds',
  );

  const selectedEmailAccountIds = getSelectedIdsForFilter(
    filters,
    emails,
    email,
    getIsAllEmailAccountsSelectedFromLocalStore(),
    'id',
    'emailAccountIds',
  );

  const selectedUsersIds = getFilteredOwner(
    filters.owners,
    owners,
    teamsAndUsersList,
  );

  const selectedClients = getFilteredClient(filters.clients, clients);

  const date = getSelectedDateForFilter(duration, startDate, endDate, filters);

  /* The user can view unlimited threads and also can perform filters */
  if (
    hasPermission(Permissions.UNIFIED_INBOX_THREAD_UNLIMITED) &&
    hasPermission(Permissions.UNIFIED_INBOX_THREAD_FILTER)
  ) {
    return getReturnValueForCaseOne({
      filters,
      page,
      search,
      category,
      selectCategory,
      selectedSequenceIds,
      selectedEmailAccountIds,
      selectedUsersIds,
      date,
      selectedClients,
    });
  }

  /* The user can view unlimited threads but cannot perform filters */
  if (hasPermission(Permissions.UNIFIED_INBOX_THREAD_UNLIMITED)) {
    return getReturnValueForCaseTwo({
      filters,
      page,
      selectedSequenceIds,
      selectedEmailAccountIds,
      selectedUsersIds,
      date,
      selectedClients,
    });
  }

  /* The user cannot view unlimited threads but can perform filters */
  if (hasPermission(Permissions.UNIFIED_INBOX_THREAD_FILTER)) {
    return getReturnValueForCaseThree({
      filters,
      search,
      category,
      selectCategory,
      selectedSequenceIds,
      selectedEmailAccountIds,
      selectedUsersIds,
      date,
      selectedClients,
    });
  }

  /* The user neither can view unlimited threads nor perform filters */
  return {
    page: 1,
    limit: 15,
    categoryId: 0,
    search: '',
    sequenceIds: selectedSequenceIds,
    emailAccountIds: selectedEmailAccountIds,
    owners: selectedUsersIds,
    date,
    clients: selectedClients,
  };
};
