import qs from 'qs';
import { isEmpty } from 'lodash';
import api from '../../../api';
import {
  AddContactsFromCSVRequestPayload,
  AddSequenceStepRequestPayload,
  AddSequenceStepVariantRequestPayload,
  CreateSequenceRequestPayload,
  DeleteSequencesRequestPayload,
  GetSequencesQueryParams,
  PauseResumeSequenceRequestPayload,
  RenameSequenceRequestPayload,
  UpdateSequenceSettingsRequestPayload,
  UpdateSequenceStepExecutionDayRequestPayload,
  UpdateSequenceStepRequestPayload,
  UpdateSequenceStepVariantDataRequestPayload,
  UpdateSequenceStepVariantRequestPayload,
  UpdateSequenceStepVariantStatusRequestPayload,
  ContactListParams,
  AddToStepParams,
  EmailWritingFactorsPayload,
  SequenceProspectBulkStatusUpdatePayload,
  SequenceProspectBulkTagAssignPayload,
  SequenceProspectBulkTagUnAssignPayload,
  SequenceProspectBulkAddToSequencePayload,
  SequenceProspectBulkRemoveFromSequencePayload,
} from '../types';
import { ResponseSuccess } from '../../../shared/types';
import { ContactStatusType } from '../enums';
import { PaginationQueryParams } from '../../../shared/types/request';
import { GetEmailRequestPayload } from '../types/emails';
import { GetTemplatesRequest } from '../../templates/types/request';
import { Source } from '../../../shared/enums/source';
import { constants } from '../../../shared/enums/constants';

// Sequence
export const createSequence = async (payload: CreateSequenceRequestPayload) =>
  api.post<ResponseSuccess>('/sequences', payload, {
    headers: { 'sh-source': Source.SequenceTab },
  });

export const getSequence = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}`);

export const getSequences = async ({
  clients,
  ...queryParams
}: GetSequencesQueryParams) =>
  api.get<ResponseSuccess>('/sequences', {
    params: { ...queryParams, ...(clients && { clientIds: clients }) },
    paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
  });

export const getSequenceStats = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/stats`);

export const getSequencesStats = async ({
  clients,
  ...queryParams
}: GetSequencesQueryParams) =>
  api.get<ResponseSuccess>('/sequences/stats', {
    params: { ...queryParams, ...(clients && { clientIds: clients }) },
    paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
  });

export const renameSequence = async (
  sequenceId: number,
  payload: RenameSequenceRequestPayload,
) => api.patch<ResponseSuccess>(`/sequences/${sequenceId}/rename`, payload);

export const pauseResumeSequence = async (
  sequenceId: number,
  payload: PauseResumeSequenceRequestPayload,
) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/pause-resume`, payload);

export const cloneSequence = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/clone/${sequenceId}`);

export const deleteSequence = async (sequenceId: number) =>
  api.delete<ResponseSuccess>(`/sequences/${sequenceId}`);

export const deleteSequences = async (payload: DeleteSequencesRequestPayload) =>
  api.post<ResponseSuccess>('/sequences/delete', payload);

// Step
export const addSequenceStep = async (
  sequenceId: number,
  payload: AddSequenceStepRequestPayload,
) => api.post<ResponseSuccess>(`/sequences/${sequenceId}/steps`, payload);

// Generic request.
export const updateSequenceStep = async (
  sequenceId: number,
  stepId: number,
  payload: UpdateSequenceStepRequestPayload,
) =>
  api.patch<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}`,
    payload,
  );

export const updateSequenceStepExecutionDay = async (
  sequenceId: number,
  stepId: number,
  payload: UpdateSequenceStepExecutionDayRequestPayload,
) => updateSequenceStep(sequenceId, stepId, payload);

export const deleteSequenceStep = async (sequenceId: number, stepId: number) =>
  api.delete<ResponseSuccess>(`/sequences/${sequenceId}/steps/${stepId}`);

// Variant
export const addSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  payload: AddSequenceStepVariantRequestPayload,
) =>
  api.post<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants`,
    payload,
  );

// Generic request.
export const updateSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantRequestPayload,
) =>
  api.patch<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants/${variantId}`,
    payload,
  );

export const saveProspectDealValue = async (
  sequenceId: string,
  prospectId: number,
  dealValue: number,
) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/deal-value`, {
    prospectId,
    dealValue,
  });

export const updateSequenceStepVariantData = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantDataRequestPayload,
) => updateSequenceStepVariant(sequenceId, stepId, variantId, payload);

export const updateSequenceStepVariantStatus = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantStatusRequestPayload,
) => updateSequenceStepVariant(sequenceId, stepId, variantId, payload);

export const deleteSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
) =>
  api.delete<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants/${variantId}`,
  );

export const cloneSequenceStepVariant = async (variantId: number) =>
  api.get<ResponseSuccess>(`/sequences/variants/clone/${variantId}`);

// Settings
export const updateSequenceSettings = async (
  sequenceId: string,
  payload: UpdateSequenceSettingsRequestPayload,
) => api.patch<ResponseSuccess>(`/sequences/${sequenceId}/settings`, payload);

export const getSequenceSteps = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/steps`);

// Import contacts csv.
export const addContactsFromCSV = async ({
  file,
  mapConfig,
  conflictAction,
  includeRiskyContact,
  includeVerification,
  tags,
  stepId,
}: AddContactsFromCSVRequestPayload) => {
  const data = new FormData();
  data.append('file', file);
  data.append('mapConfig', JSON.stringify(mapConfig));
  data.append('conflictAction', conflictAction);
  data.append('includeRiskyContacts', includeRiskyContact ? '1' : '0');
  data.append('verifyContacts', includeVerification ? '1' : '0');
  tags && data.append('tags', JSON.stringify(tags));
  return api.post<ResponseSuccess>(`/contacts/sequence-async/${stepId}`, data, {
    headers: { 'sh-source': Source.SequenceProspectImportCsv },
  });
};

export const addContactsFromCSVWithoutStep = async ({
  file,
  mapConfig,
  conflictAction,
  includeVerification,
  tags,
  stepId,
}: AddContactsFromCSVRequestPayload) => {
  const data = new FormData();
  data.append('file', file);
  data.append('mapConfig', JSON.stringify(mapConfig));
  data.append('conflictAction', conflictAction);
  data.append('verifyContacts', includeVerification ? '1' : '0');
  tags && data.append('tags', JSON.stringify(tags));
  stepId && data.append('stepId', JSON.stringify(stepId));
  return api.post<ResponseSuccess>(`/contacts/import-async`, data, {
    headers: { 'sh-source': Source.ProspectImportCsv },
  });
};

// Schedule.
export const getAllSchedules = async () => api.get('/schedules');

// Connected Email Accounts.
export const getConnectedEmails = async () =>
  api.get('/email-account/connected');

// Contact API

// Get the filter list of steps
export const getSequenceContactList = async (
  sequenceId: string,
  params: ContactListParams,
) => api.get(`/sequences/${parseInt(sequenceId, 10)}/contacts`, { params });

// Get count of related to status
export const getContactStatusCount = async (
  sequenceId: string,
  params: ContactListParams,
) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/contacts/status/count`, {
    params,
  });

// Change Status of Contacts of Sequence.
export const changeStatusOfContactsOfSequence = async (
  status: ContactStatusType,
  contactAndStepIds: any[],
  pauseDelayInDays?: number,
) =>
  api.patch(`/contacts/status`, {
    contactAndStepIds,
    status,
    ...(pauseDelayInDays && { pauseDelayInDays }),
  });

// Remove Contacts From Sequence.
export const removeContactFromSequence = async (
  sequenceId: string,
  contactIds: number[],
) =>
  api.patch(`/sequences/${parseInt(sequenceId, 10)}/contacts`, {
    contactIds,
  });

// Get Contact Sequence List.
export const getContactSequenceList = async () =>
  api.get<ResponseSuccess>('/sequences/list?numberOfSteps=gt:0');

// Get Steps by Sequence for Contacts.
export const getContactStepList = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/steps`);

// Add Contact to sequence's step Request.
export const addContactsToStep = async ({
  stepId,
  contactIds,
  sequenceId,
}: AddToStepParams) =>
  api.post<ResponseSuccess>(`/sequences/${parseInt(sequenceId, 10)}/contacts`, {
    stepId,
    contactIds,
  });
export const getUsersList = async (queryParams?: PaginationQueryParams) =>
  api.get<ResponseSuccess>(`/team/members`, { params: queryParams });

export const uploadImage = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
) => api.post('/attachment-broker/upload', formData, { onUploadProgress });

export const finishOnboarding = async () =>
  api.delete<ResponseSuccess>(`/user/onboarding`);

export const getAdminSettings = async () =>
  api.get<ResponseSuccess>(`/accounts`);

export const getSequenceMailSendingStatus = async (sequenceId: string) =>
  api.get<ResponseSuccess>(
    `/sequences/${parseInt(sequenceId, 10)}/send-status`,
  );

export const getSequenceConfig = async (sequenceId: string) =>
  api.get<ResponseSuccess>(`/sequences/${parseInt(sequenceId, 10)}/config`);

// Get first sequence created for user.
export const getFirstSequence = async () =>
  api.get<ResponseSuccess>('/sequences/first');

// Get sample sequence
export const getSampleSequence = async () =>
  api.get<ResponseSuccess>('sequences/sample');

export const getUserTimeZone = async () =>
  api.get<ResponseSuccess>('/user/time-zone');

// Create sample steps for sequence.
export const createSampleSteps = async (sequenceId: number) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/steps/sample`);

// Add sample prospects to step.
export const addSampleProspectsToStep = async (stepId: number) =>
  api.post<ResponseSuccess>(`/contacts/sequence/${stepId}/sample`);

export const getTemplates = async ({
  limit = 50,
  ...rest
}: GetTemplatesRequest) =>
  api.get(`templates`, {
    params: { limit, ...rest },
  });

export const getEmailStepsOfStatus = async (sequenceId, params) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails/step-count`, {
    params,
  });

export const getEmailStatusOfStep = async (sequenceId, params) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails/status-count`, {
    params,
  });

export const getEmailList = async ({
  sequenceId,
  ...rest
}: GetEmailRequestPayload) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails`, {
    params: rest,
  });

export const getSenderEmails = async (sequenceId: string) =>
  api.get(`sequences/${sequenceId}/emails/sender-filter`);

export const sendScheduledEmail = async ({
  sequenceId,
  sequenceContactHistoryId,
  emailAccountId,
  variantId,
}) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/emails/send`, {
    sequenceProspectTaskId: sequenceContactHistoryId,
    emailAccountId,
    variantId,
  });

export const changeEmailStatus = async ({
  sequenceId,
  sequenceContactHistoryId,
  status,
}) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/emails/status`, {
    sequenceProspectTaskIds: [sequenceContactHistoryId],
    status,
  });

// Get the filter list of steps
export const getSequenceProspectActivity = async (
  sequenceId: string,
  prospectId: string,
) =>
  api.get(`/sequences/${parseInt(sequenceId, 10)}/prospects/activity`, {
    params: { prospectId },
  });

// Get user customDomain
export const getCustomDomains = async () => api.get('/custom-domain');

// Get sequence prospect verification stats
export const getSequenceProspectVerificationStats = async (
  sequenceId: string,
) => api.get(`/sequences/${sequenceId}/prospect-verification-stats`);

// Send sequence prospect verify start request
export const sendSequenceProspectVerifyStart = async (sequenceId: string) =>
  api.post(`sequences/${sequenceId}/verify-prospects`);

// Upload Attachment Request
export const uploadAttachment = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
  cancelToken,
) =>
  api.post('/attachment-broker/attachment', formData, {
    onUploadProgress,
    cancelToken,
  });

// Delete Attachment Request
export const deleteAttachment = async (
  attachmentId: number,
  deleteFromTemplate: boolean,
  sequenceStepVariantId: number,
) =>
  api.delete(`/attachment-broker/attachment/${attachmentId}`, {
    data: { deleteFromTemplate, sequenceStepVariantId },
  });

// Get sent email preview
export const getSentEmailPreview = async (sequenceProspectTaskId: string) =>
  api.get(
    `/sequences/sequence-prospect-task/${sequenceProspectTaskId}/sent-email`,
  );

// Get sequence score
export const getSequenceScore = async (sequenceId: string) =>
  api.get(`/sequences/${sequenceId}/sequence-score`);

// Variant Email Writing Score
export const createEmailWritingFactors = async ({
  sequenceId,
  stepId,
  emailWritingFactors,
}: EmailWritingFactorsPayload) =>
  api.post(`/sequences/${sequenceId}/steps/${stepId}/email-writing-scan`, {
    emailWritingScans: emailWritingFactors,
  });

export const updateEmailWritingFactors = async ({
  sequenceId,
  stepId,
  emailWritingFactors,
}: EmailWritingFactorsPayload) => {
  const { variantId, ...payload } = emailWritingFactors[0];

  return api.patch(
    `/sequences/${sequenceId}/steps/${stepId}/variant/${variantId}/email-writing-scan`,
    payload,
  );
};

export const getLinkedEmailAccounts = async (
  sequenceId: string,
  pageNum: number,
) =>
  api.get(`/sequences/${sequenceId}/connected-email-accounts`, {
    params: {
      pageSize: 10,
      pageNum,
    },
  });

export const linkEmailToSequence = async (
  sequenceId: string,
  emailAccountIds: number[],
) =>
  api.post(`sequences/${sequenceId}/add-email-accounts`, { emailAccountIds });

export const getAllNotLinkedEmailAccounts = async (
  sequenceId: string,
  clientIds: string,
  search: string,
  pageNum: number,
) =>
  api.get(`sequences/${sequenceId}/add-emails/list`, {
    params: {
      pageSize: constants.DEFAULT_PAGE_SIZE,
      pageNum,
      search,
      ...(clientIds && { clientIds }),
    },
  });

export const removeConnectedEmailAccount = async (
  sequenceId: string,
  emailAccountId: number,
) => api.delete(`sequences/${sequenceId}/emailAccount/${emailAccountId}`);

export const getNotLinkedEmailAccountsCount = async (
  sequenceId: string,
  search: string,
  clientIds: string,
) =>
  api.get(`sequences/${sequenceId}/add-emails/list-total-count`, {
    params: {
      search,
      ...(clientIds && { clientIds }),
    },
  });

export const resendFailedEmail = async ({
  sequenceId,
  sequenceContactHistoryId,
}) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/emails/resend-failed`, {
    sequenceProspectTaskId: sequenceContactHistoryId,
  });

export const onOutcomeChange = async (
  sequenceId: number,
  prospectId: number,
  outcomeId: number,
) =>
  api.patch<ResponseSuccess>(`sequences/${sequenceId}/outcome`, {
    prospectId,
    outcomeId,
  });

export const getTaskAssigneeList = (userId) =>
  api.get<ResponseSuccess>(`/user/task-assignee`);

export const bulkPauseSequence = async (sequenceIds: number[]) =>
  api.patch<ResponseSuccess>('/sequences/bulk-pause', { sequenceIds });

export const bulkResumeSequence = async (sequenceIds: number[]) =>
  api.patch<ResponseSuccess>('/sequences/bulk-resume', { sequenceIds });

export const getAllConnectedEmailAccounts = async (sequenceId: number) =>
  api.get<ResponseSuccess>(
    `/sequences/${sequenceId}/all-connected-email-accounts`,
  );

export const sequenceProspectBulkStatusUpdate = async ({
  sequenceId,
  status,
  deSelectedContactIds,
  contactsFilter,
  pauseDelayInDays,
}: SequenceProspectBulkStatusUpdatePayload) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/contacts/bulk-status`, {
    status,
    ...(deSelectedContactIds?.length > 0 && {
      deSelectedContactIds,
    }),
    ...(!isEmpty(contactsFilter) && { contactsFilter }),
    ...(pauseDelayInDays !== undefined && { pauseDelayInDays }),
  });

export const sequenceProspectBulkTagAssign = async ({
  sequenceId,
  ids,
  newTags,
  deSelectedContactIds,
  contactsFilter,
}: SequenceProspectBulkTagAssignPayload) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/tag/bulk-assign`, {
    ids,
    newTags,
    ...(deSelectedContactIds?.length > 0 && {
      deSelectedContactIds,
    }),
    ...(!isEmpty(contactsFilter) && { contactsFilter }),
  });

export const sequenceProspectBulkTagUnAssign = async ({
  sequenceId,
  tagIds,
  deSelectedContactIds,
  contactsFilter,
}: SequenceProspectBulkTagUnAssignPayload) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/tag/bulk-un-assign`, {
    tagIds,
    ...(deSelectedContactIds?.length > 0 && {
      deSelectedContactIds,
    }),
    ...(!isEmpty(contactsFilter) && { contactsFilter }),
  });

export const sequenceProspectBulkAddToSequence = async ({
  sequenceId,
  stepId,
  deSelectedContactIds,
  contactsFilter,
}: SequenceProspectBulkAddToSequencePayload) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/bulk-sequence-contacts`, {
    stepId,
    ...(deSelectedContactIds?.length > 0 && {
      deSelectedContactIds,
    }),
    ...(!isEmpty(contactsFilter) && { contactsFilter }),
  });

export const sequenceProspectBulkRemoveFromSequence = async ({
  sequenceId,
  deSelectedContactIds,
  contactsFilter,
}: SequenceProspectBulkRemoveFromSequencePayload) =>
  api.patch<ResponseSuccess>(
    `/sequences/${sequenceId}/contacts/bulk-contacts`,
    {
      ...(deSelectedContactIds?.length > 0 && {
        deSelectedContactIds,
      }),
      ...(!isEmpty(contactsFilter) && { contactsFilter }),
    },
  );
