import { EmailInboxFilters } from '../../type';

const emailInboxFilterKey = 'email-inbox-filters';
const isAllSequencesSelectedInEmailInboxFilter =
  'is-all-sequences-selected-in-email-inbox-filters';
const isAllEmailAccountsSelectedInEmailInboxFilter =
  'is-all-email-accounts-selected-in-email-inbox-filters';
const isAllOwnersSelectedInEmailInboxFilter =
  'is-all-owners-selected-in-email-inbox-filters';
const isAllClientsSelectedInEmailInboxFilter =
  'is-all-clients-selected-in-email-inbox-filters';
const emailInboxMetaDetailsKey = 'email-inbox-meta-details';

export const setEmailInboxFiltersInLocalStore = (
  filters: EmailInboxFilters,
  isAllSequencesSelected: boolean,
  isAllEmailAccountsSelected: boolean,
  isAllOwnersSelected: boolean,
  isAllClientsSelected: boolean,
) => {
  localStorage.setItem(emailInboxFilterKey, JSON.stringify(filters));
  localStorage.setItem(
    isAllSequencesSelectedInEmailInboxFilter,
    JSON.stringify(isAllSequencesSelected),
  );
  localStorage.setItem(
    isAllEmailAccountsSelectedInEmailInboxFilter,
    JSON.stringify(isAllEmailAccountsSelected),
  );
  localStorage.setItem(
    isAllOwnersSelectedInEmailInboxFilter,
    JSON.stringify(isAllOwnersSelected),
  );
  localStorage.setItem(
    isAllClientsSelectedInEmailInboxFilter,
    JSON.stringify(isAllClientsSelected),
  );
};

export const getEmailInboxFiltersFromLocalStore = (): EmailInboxFilters =>
  JSON.parse(localStorage.getItem(emailInboxFilterKey));

export const getIsAllSequencesSelectedFromLocalStore = (): boolean =>
  JSON.parse(localStorage.getItem(isAllSequencesSelectedInEmailInboxFilter));

export const getIsAllEmailAccountsSelectedFromLocalStore = (): boolean =>
  JSON.parse(
    localStorage.getItem(isAllEmailAccountsSelectedInEmailInboxFilter),
  );

export const getIsAllOwnersSelectedFromLocalStore = (): boolean =>
  JSON.parse(localStorage.getItem(isAllOwnersSelectedInEmailInboxFilter));

export const getIsAllClientsSelectedFromLocalStore = (): boolean =>
  JSON.parse(localStorage.getItem(isAllClientsSelectedInEmailInboxFilter));

export const removeEmailInboxFiltersFromLocalStore = (): void => {
  localStorage.removeItem(emailInboxFilterKey);
  localStorage.removeItem(isAllSequencesSelectedInEmailInboxFilter);
  localStorage.removeItem(isAllEmailAccountsSelectedInEmailInboxFilter);
  localStorage.removeItem(isAllOwnersSelectedInEmailInboxFilter);
  localStorage.removeItem(isAllClientsSelectedInEmailInboxFilter);
};

export const getEmailInboxMetaDetailsFromLocalStore = () =>
  JSON.parse(localStorage.getItem(emailInboxMetaDetailsKey));
export const setEmailInboxMetaDetailsInLocalStore = (metaDetails: any) =>
  localStorage.setItem(emailInboxMetaDetailsKey, JSON.stringify(metaDetails));
export const removeEmailInboxMetaDetailsFromLocalStore = () => {
  localStorage.removeItem(emailInboxMetaDetailsKey);
};
