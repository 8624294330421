import { DateTime } from 'luxon';
import { DatePickerOption } from './types';

export const getCurrentTimeInUTC = () => DateTime.fromJSDate(new Date());

const durationOptions: DatePickerOption[] = [
  {
    displayText: 'Today',
    label: 'today',
    startDate: getCurrentTimeInUTC(),
    endDate: getCurrentTimeInUTC(),
    value: 'today',
    key: 'today',
  },
  {
    displayText: 'Yesterday',
    label: 'yesterday',
    startDate: getCurrentTimeInUTC().minus({ days: 1 }),
    endDate: getCurrentTimeInUTC(),
    value: 'yesterday',
    key: 'yesterday',
  },
  {
    displayText: 'Last 7 Days',
    label: 'last_7_days',
    startDate: getCurrentTimeInUTC().minus({ days: 7 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_7_days',
    key: 'last_7_days',
  },
  {
    displayText: 'Last 14 Days',
    label: 'last_14_days',
    startDate: getCurrentTimeInUTC().minus({ days: 14 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_14_days',
    key: 'last_14_days',
  },
  {
    displayText: 'Last 30 Days',
    label: 'last_30_days',
    startDate: getCurrentTimeInUTC().minus({ days: 30 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_30_days',
    key: 'last_30_days',
  },
  {
    displayText: 'Last 90 Days',
    label: 'last_90_days',
    startDate: getCurrentTimeInUTC().minus({ days: 90 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_90_days',
    key: 'last_90_days',
  },
  {
    displayText: 'This Week',
    label: 'this_week',
    startDate: getCurrentTimeInUTC().startOf('week'),
    endDate: getCurrentTimeInUTC(),
    value: 'this_week',
    key: 'this_week',
  },
  {
    displayText: 'Last Week',
    label: 'last_week',
    startDate: getCurrentTimeInUTC().startOf('week').minus({ weeks: 1 }),
    endDate: getCurrentTimeInUTC().startOf('week').minus({ days: 1 }),
    value: 'last_week',
    key: 'last_week',
  },
  {
    displayText: 'This Month',
    label: 'this_month',
    startDate: getCurrentTimeInUTC().startOf('month'),
    endDate: getCurrentTimeInUTC(),
    value: 'this_month',
    key: 'this_month',
  },
  {
    displayText: 'Last Month',
    label: 'last_month',
    startDate: getCurrentTimeInUTC()
      .startOf('month')
      .minus({ months: 1 })
      .startOf('day'),
    endDate: getCurrentTimeInUTC().startOf('month').minus({ days: 1 }),
    value: 'last_month',
    key: 'last_month',
  },
  {
    displayText: 'This Quarter',
    label: 'this_quarter',
    startDate: getCurrentTimeInUTC().startOf('month'),
    endDate: getCurrentTimeInUTC()
      .startOf('month')
      .plus({ months: 2 })
      .endOf('month'),
    value: 'this_quarter',
    key: 'this_quarter',
  },
  {
    displayText: 'Last Quarter',
    label: 'last_quarter',
    startDate: getCurrentTimeInUTC()
      .startOf('month')
      .minus({ months: 3 })
      .startOf('day'),
    endDate: getCurrentTimeInUTC().startOf('month').minus({ days: 1 }),
    value: 'last_quarter',
    key: 'last_quarter',
  },
  {
    displayText: 'This Year',
    label: 'this_year',
    startDate: getCurrentTimeInUTC().startOf('year'),
    endDate: getCurrentTimeInUTC(),
    value: 'this_year',
    key: 'this_year',
  },
  {
    displayText: 'Last Year',
    label: 'last_year',
    startDate: getCurrentTimeInUTC()
      .startOf('year')
      .minus({ years: 1 })
      .startOf('day'),
    endDate: getCurrentTimeInUTC().startOf('year').minus({ days: 1 }),
    value: 'last_year',
    key: 'last_year',
  },
  {
    displayText: 'Custom',
    label: 'custom',
    startDate: getCurrentTimeInUTC().minus({ days: 30 }),
    endDate: getCurrentTimeInUTC(),
    value: 'custom',
    key: 'custom',
  },
];

export default durationOptions;
