import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../home';
import FixedHome from '../../shared/components/fixed-home';
import Auth from '../auth';
import TempNavigation from '../temp/temp-navigation';
import ResetPasswordCallback from '../auth/components/reset-password-callback';
import TempAuthIndicator from '../temp/temp-auth-indicator';
import ProtectedRoute from '../../shared/components/protected-route';
import ConnectEmailAccountCallback from '../settings/components/connect-email-account-callback';
import ConfigRoute from '../../shared/components/config-route';
import Error404 from '../../shared/components/error-404';
import VerifyEmailCallback from '../auth/components/verify-email-callback';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import connectWhitelabelEmailAccountCallback from '../settings/components/connect-whitelabel-email-account-callback';

const App: React.FC = () => (
  <>
    <FixedHome />
    <ErrorBoundaryWrapper>
      <Switch>
        <ConfigRoute
          title="Login"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/login"
          component={Auth}
        />
        <ConfigRoute
          title="Agency Login"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/agency/login"
          component={Auth}
        />
        <ConfigRoute
          title="Sign up"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/signup"
          component={Auth}
        />
        <ConfigRoute
          title="Sign up"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/LTD-Signup"
          component={Auth}
        />
        <ConfigRoute
          title="Reset Password"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/reset-password/:code"
          component={ResetPasswordCallback}
        />
        <ConfigRoute
          title="Accept Invitation"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/accept-invite/:token"
          component={Auth}
        />
        <ConfigRoute
          title="Reset Password"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/reset-password"
          component={Auth}
        />
        <ConfigRoute
          title="Verify Email"
          exact
          path="/verify-email"
          component={VerifyEmailCallback}
        />
        <ProtectedRoute exact path="/LTD-Checkout" component={Auth} />
        <ProtectedRoute exact path="/verify-email" component={Home} />
        <ProtectedRoute exact path="/email-insights" component={Home} />
        <ProtectedRoute exact path="/tasks" component={Home} />
        <ProtectedRoute exact path="/prospects" component={Home} />
        <ProtectedRoute exact path="/leads" component={Home} />
        <ProtectedRoute exact path="/reports" component={Home} />
        <ProtectedRoute exact path="/reports/:tab" component={Home} />
        <ProtectedRoute exact path="/growth-hub" component={Home} />
        <ProtectedRoute exact path="/agency-portal" component={Home} />
        <ProtectedRoute exact path="/clients" component={Home} />
        <ProtectedRoute path="/warmup" component={Home} />
        <ProtectedRoute exact path="/templates" component={Home} />
        <ProtectedRoute exact path="/conversations" component={Home} />
        <ProtectedRoute exact path="/conversations/:hashId" component={Home} />
        <ProtectedRoute
          exact
          path="/settings/email-accounts/verify/:method"
          component={ConnectEmailAccountCallback}
        />
        <ConfigRoute
          exact
          path="/settings/whitelabel/email-accounts/verify/:method"
          component={connectWhitelabelEmailAccountCallback}
        />
        <ProtectedRoute path="/settings" component={Home} />
        <ProtectedRoute path="/block" component={Home} />
        <ProtectedRoute exact path="/sequence" component={Home} />
        <ProtectedRoute
          exact
          path="/sequence/:sequenceId/:activeTab"
          component={Home}
        />
        <Redirect from="/sequence" to="/sequence" />
        <Route exact path="/navigation" component={TempNavigation} />
        <Route exact path="/auth-indicator" component={TempAuthIndicator} />
        <Redirect exact from="/" to="/sequence" />
        <ConfigRoute
          exact
          path="/not-found"
          title="Not Found"
          type={ConfigRoute.RouteType.Default}
          component={Error404}
        />
        <ConfigRoute title="Not Found" component={Error404} />
      </Switch>
    </ErrorBoundaryWrapper>
  </>
);

export default App;
