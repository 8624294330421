import { OutcomeSentiment } from '../../../settings/enums/custom-outcome';
import { EmailCategoryOption, EmailCategoryType } from '../../type';

export const getAllCategoryObject = (totalThreads = 0) => ({
  key: '0',
  id: 0,
  name: 'All Outcomes',
  emoji: '📥',
  totalEmailsInCategory: totalThreads,
  textColor: '#1F2937',
  bgColor: '#FFFFFF',
  sentiment: OutcomeSentiment.Neutral,
  isDefault: true,
});

export const getEmailCategoriesForFilter = (
  emailCategories: EmailCategoryType[],
): EmailCategoryOption[] => {
  if (!emailCategories || emailCategories.length === 0) {
    return [];
  }

  let totalThreads = 0;

  const emailCategoriesFiltersOptions: EmailCategoryOption[] = emailCategories.map(
    (item) => {
      totalThreads += Number(item?.totalEmailsInCategory);
      return { key: item.id.toString(), ...item };
    },
  );

  emailCategoriesFiltersOptions.unshift(getAllCategoryObject(totalThreads));

  return emailCategoriesFiltersOptions;
};
