import { EmailInboxFilters } from '../type';
import { Sequence } from '../../reports/types';
import { getDefaultFilters } from './utils/get-default-filters';
import { getIsRequestPending } from '../../../shared/utils/get-request-status';
import { SELECT_ALL_EMAIL_THREADS_LIMIT } from '../enum/unified-inbox.enum';
import { TeamsAndUsersList } from '../../settings/types/users-and-teams';
import { getAllSelectOwners } from './utils/get-email-inbox-filters-based-on-query';
import { AgencyClientFilter } from '../../agency-client-management/types/types';

/* Max Days Range for Filter */
export const MAX_DAYS_RANGE = 90;

/* Function to check whether the filters are modified or not.
It returns true, if the filters has been modified, else return false  */
export const checkIsFilterModified = (filter: EmailInboxFilters) =>
  JSON.stringify(filter) !== JSON.stringify(getDefaultFilters());

/* Function to check is the filters get API has been succeeded or not,
  It will be used to determine, weather to show or hide loading state UI  */
export const getIsFilterLoading = ({
  getEmailCategoriesRequestStatus,
  getEmailAccountsListForFilterRequestStatus,
  getSequencesListForFilterRequestStatus,
  getAgencyClientsDropdownRequestStatus,
  getTeamsAndMembersListRequestStatus,
}) => ({
  agencyClientFilterLoading: getIsRequestPending(
    getAgencyClientsDropdownRequestStatus,
  ),
  sequencesFilterLoading: getIsRequestPending(
    getSequencesListForFilterRequestStatus,
  ),
  emailAccountsFilterLoading: getIsRequestPending(
    getEmailAccountsListForFilterRequestStatus,
  ),
  categoriesFilterLoading: getIsRequestPending(getEmailCategoriesRequestStatus),
  ownersFilterLoading: getIsRequestPending(getTeamsAndMembersListRequestStatus),
});

/* Function to check weather all sequences is selected or not
for Email Inbox Filters */
export const checkIsAllSequencesSelected = (
  sequencesIds: number[] = [],
  sequences: Sequence[],
): boolean =>
  // !sequencesIds ||
  sequencesIds.length === 0 || sequences.length === sequencesIds.length;

/* Function to check weather all email accounts is selected or not
for Email Inbox Filters */
export const checkIsAllEmailAccountsSelected = (
  emailAccountIds: number[] = [],
  sequences: { id: number; fromEmail: string }[],
): boolean =>
  // !emailAccountIds ||
  emailAccountIds.length === 0 || sequences.length === emailAccountIds.length;

export const checkIsAllOwnersSelected = (
  owners: number[],
  teamsAndUsersList: TeamsAndUsersList[],
): boolean => getAllSelectOwners(teamsAndUsersList).length === owners.length;

export const checkIsAllClientsSelected = (
  clientIds: number[],
  clients: AgencyClientFilter[],
): boolean => clients.length === 0 || clientIds.length === clients.length;

export const getUnifiedInboxPathName = (hashId: string): string =>
  hashId ? `/conversations/${hashId}` : '/conversations';

export const getTotalSelectedEmailCount = (totalItems: number) =>
  totalItems > SELECT_ALL_EMAIL_THREADS_LIMIT
    ? SELECT_ALL_EMAIL_THREADS_LIMIT
    : totalItems;

export const checkIsFilterApplied = (unifiedInboxFilters): boolean => {
  let isFilterApplied = false;

  Object.keys(unifiedInboxFilters).forEach((key) => {
    if (unifiedInboxFilters[key].length > 0) {
      if (key === 'status') {
        const filterApplied = unifiedInboxFilters[key].find(
          (item) => !item?.isHeaderFilter,
        );

        if (filterApplied) {
          isFilterApplied = true;
        }
      } else {
        isFilterApplied = true;
      }
    }
  });

  return isFilterApplied;
};
